<template>
    <div v-if="parsedProduct.active" class="text-nowrap">
        <button class="upq-add-bale-btn btn btn-primary text-center d-inline-block py-1 px-2 rounded-0"
                v-if="hasBaleBtn"
                v-on:click="orderBaleToCart" >{{parsedProduct.bale_qty}}<br>
            <small>add bale</small>
        </button>

        <div class="border bg-upq-yellow rounded-0 d-inline-block py-1 ">
            <button v-if="this.large" class="btn basic rounded-0 pr-0" v-on:click="decreaseQty" ><i class="fas fa-minus"></i></button>
            <button v-else class="btn basic px-1 rounded-0" v-on:click="decreaseQty" ><i class="fas fa-minus"></i></button>
            <!--<span class="stepper" >{{Number(orderQty)}}</span>-->
            <input name="product_qty"
                   :value="Number(orderQty)"
                   :step="qtyStep"
                   :min="qtyStep"
                   type="number"
                   v-on:change="onQtyTypedChange"
                   class="upq-qty-input text-center" >

            <button v-if="this.large" class="btn basic pl-0 rounded-0" v-on:click="increaseQty" ><i class="fas fa-plus"></i></button>
            <button v-else class="btn basic px-1 rounded-0" v-on:click="increaseQty" ><i class="fas fa-plus"></i></button>

            <button class="btn basic upq-add-to-cart-btn rounded-0 pr-1"
                    v-bind:class="{ 'font-weight-bold px-0': this.large }"
                    v-on:click="addItemToCart" name="submit"><img class="cart-icon" alt="shopping cart image" src="/icons/cart-icon.png"> ADD</button>

            <span v-if="loggedIn">
                <button v-if="isFavorite" v-tooltip="'Remove from Favorites'" v-bind:class="{ 'font-weight-bold': this.large }" v-on:click="removeFavorite" class="btn basic pt-2 rounded-0" alt="Add to Favorites">
                    <i class="fas fa-heart fa-2x "></i>
                </button>
                <button v-else v-tooltip="'Add to Favorites'" v-bind:class="{ 'font-weight-bold': this.large }" v-on:click="makeFavorite" class="btn basic pt-2 rounded-0" alt="Add to Favorites">
                    <i class="far fa-heart fa-2x "></i>
                </button>
            </span>

        </div>
        <div class="d-block alert-danger text-wrap p-1" v-if="this.error">
            <small>{{ this.error }}</small>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['product', 'canUpdate', 'large', 'productUOMQty', 'productUOM', 'showBaleBtn', 'loggedIn'],

        data() {
            return {
                body: '',
                error:null,
                show: false,
                selectedQty:0,
                nowFavorite:false
            }
        },


        computed: {
            isFavorite(){
                for(var i = 0, l = this.$root.favoriteProducts.length; i<l; i++){
                    if(this.$root.favoriteProducts[i].id === this.parsedProduct.id){
                        return true;
                    }
                }
                return false;
            },

            hasBaleBtn(){
                let parsedProduct = this.parsedProduct;
                return parsedProduct.bale_qty != null && this.showBaleBtn == true;
            },
            //value displayed for 'selected quantity'.
            orderQty(){
                let p = this.parsedProduct;

                if(p.quantity && this.selectedQty == 0){
                    this.selectedQty = p.quantity;
                    return this.selectedQty;
                }
                else if(this.selectedQty>0){
                    return this.selectedQty;
                }else{
                    return this.qtyStep;
                }
            },

            parsedProduct(){
                return JSON.parse(this.product)
            },

            //extracted from the product directly.
            qtyStep() {
                let p = this.parsedProduct;
                return Number(p.full_record.SoldInQuantity);
            },

            orderItem() {
                if(this.error) return;

                let p = this.parsedProduct;
                let itemQty = parseInt(this.selectedQty);
                let minQuant = this.qtyStep;
                //calculate price based on tier sell prices
                let tierPrice = 0;

                if(itemQty == 0){
                    itemQty = this.orderQty;
                }

                if(p.bale_price_markup !== null && itemQty === p.bale_qty){
                    tierPrice = p.bale_price_markup;
                }
                else if(p.bundle_price_markup !== null){
                    tierPrice = p.bundle_price_markup;
                }
                //below: this is the old system - still using for uom=case and others.
                else{
                    p.marked_pricing.Tiers[0].Sell.forEach(function (sellTier, index){
                        if( Number(itemQty) >= Number(sellTier.Quantity) ){
                            tierPrice = sellTier.Price;
                        }
                        else if(index == 1 && Number(itemQty) <= Number(sellTier.Quantity)){
                            tierPrice = sellTier.Price;
                        }
                    });
                }

                if(itemQty === 0){ itemQty = minQuant; }

                //setup basic cart item id
                let itemId = p.sku;
                //if the item can be sold as bale check the qty and compare it to bale_qty
                if(p.bale_qty !== null && parseInt(itemQty) === parseInt(p.bale_qty)){
                    itemId = itemId + '-B';
                }

                let orderedItem = {
                    'id':           itemId,
                    'name':         p.name,
                    'qty':          itemQty,
                    'price':        Number(this.roundUp(tierPrice, 2)),//Number(tierPrice),
                    'attributes':[
                        {
                            'actualItmId':p.sku,
                            'image':p.image_name,
                            'productPath':'/product/'+ p.id,
                            'minQty': minQuant,
                            'tiers' : p.marked_pricing.Tiers[0].Sell,
                            'bundlePrice': Number(this.roundUp(p.bundle_price_markup, 2)) || null,
                            'bundleQty':p.bundle_qty || null,
                            'balePrice':Number(this.roundUp(p.bale_price_markup, 2)) || null,
                            'baleQty':p.bale_qty || null,
                            'productUOMQty': this.productUOMQty,
                            'productUOM':this.productUOM,
                        }
                    ]
                };
                return orderedItem;
            }
        },

        methods: {
            removeFavorite(){
                let me = this;
                var url = '/remove-favorite/' + this.parsedProduct.id;
                fetch(url).then(function (response) {
                    if(response.ok === true) {
                        me.$root.loadFavorites();
                    }
                });
            },

             makeFavorite(){
                 let me = this;
                var url = '/add-favorite/' + this.parsedProduct.id;
                fetch(url).then(function (response) {
                    if(response.ok === true) {
                        me.$root.loadFavorites();
                    }
                });
            },
            //method used when the user clicks to add a whole bale as a qty for a product.
            orderBaleToCart(){
                let p = this.parsedProduct;
                let itemQty = Number(p.bale_qty);

                //this item is always in a bale.
                let orderedItem = {
                    'id':           p.sku + '-B',
                    'name':         p.name,
                    'qty':          itemQty,
                    'price':        Number(p.bale_price_markup),//Number(this.roundUp(p.bale_price_markup, 2)).toFixed(2),
                    'attributes':[
                        {
                            'actualItmId':p.sku,
                            'image':p.image_name,
                            'productPath':'/product/'+ p.id,
                            'minQty': p.bundle_qty,
                            'tiers' : p.marked_pricing.Tiers[0].Sell,
                            'balePrice':Number(p.bale_price_markup),//Number(this.roundUp(p.bale_price_markup, 2)).toFixed(2) || null,
                            'baleQty':p.bale_qty || null,
                            'bundlePrice':Number(p.bundle_price_markup),//Number(this.roundUp(p.bundle_price_markup, 2)).toFixed(2) || null,
                            'bundleQty':p.bundle_qty || null,
                            'productUOMQty': this.productUOMQty,
                            'productUOM':this.productUOM,
                        }
                    ]
                };

                this.$root.addItem(orderedItem);
            },

            onQtyTypedChange(evt){
                this.selectedQty = Number(evt.currentTarget.value);
                if(!(this.selectedQty % Number(this.qtyStep) == 0)){
                    this.error = 'You can buy this product only in quantities of ' + this.qtyStep + ' at a time.';
                }else{
                    this.error = null;
                }
            },

            decreaseQty(){
                this.error = null;

                //reset selected qty if it is not an allowed value
                if(!(this.selectedQty % Number(this.qtyStep) == 0)){
                    this.selectedQty = this.qtyStep;
                }

                if( this.selectedQty > this.qtyStep){
                    this.selectedQty -= this.qtyStep;

                    if(this.canUpdate == 'true'){
                        this.$root.updateQty({'id':this.parsedProduct.id, 'qty': this.selectedQty});
                    }
                }

            },

            increaseQty(){
                this.error = null;

                //reset selected qty if it is not an allowed value
                if(!(this.selectedQty % Number(this.qtyStep) == 0)){
                    this.selectedQty = this.qtyStep;
                }

                if(this.selectedQty === 0){
                    this.selectedQty = this.qtyStep;
                }
                this.selectedQty += this.qtyStep;

                if(this.canUpdate == 'true'){
                    this.$root.updateQty({'id':this.parsedProduct.id, 'qty': this.selectedQty});
                }
            },

            addItemToCart(){
                this.$root.addItem(this.orderItem);
            },

            //returns index of attr key or false
            getIndexFor(attrValue, array){
                let indx = -1;
                array.forEach(function (value, index) {
                    if(value[attrValue] !== null){
                        indx = index;
                    }
                });
                return indx;
            },

            //allways round up.
            roundUp: function (value, n) {
                //rounding to the nearest 10
                let floatVal = parseFloat(value);
                //this does not work for 0.xxx numbers - it returns 0 allways
                // let p = Math.round(floatVal / 10) * 10;
                let p = Math.round(floatVal * Math.pow(10, n)) / Math.pow(10, n);
                return p;
                //rounding to the larger.
                //return Math.ceil(value * Math.pow(10, n)) / Math.pow(10, n);
            }
        }
    }
</script>

<style>
    .stepper {
        width: 50px;
        padding: 3px 5px;
        text-align: center;
        /*height: 30px;*/
        border: 1px solid #C0C0C0;
        display: inline-block;
    }

    .upq-add-to-cart-btn:hover,
    .upq-add-to-cart-btn:active {
        background-color: #fcd900;
    }
</style>
