<template>
    <div v-if="this.$root.$data.items.length" >
        <div class="card py-4">
            <div class="card-body">
                <h4 class="text-center font-roboto-slab">Order Summary</h4>
                <small v-if="this.orderNumber">Order Number: {{this.orderNumber}}</small>
                <small v-if="this.PONumber"> / PON: {{this.PONumber}}</small>

                <table v-if="this.showDetails" class="table table-borderless mt-5 table-sm">
                    <tr v-for="item in this.$root.$data.items">
                        <td class="px-3 w-75" colspan="2">
                            {{ actualItemId(item) }} <strong>x{{ item.quantity }}/{{pricePerQty(item)}} ea.</strong>
                        </td>
                        <td class="text-right pr-3">
                            <strong>${{ (pricePerQty(item)*Number(item.quantity)).toFixed(2) }}</strong>
                        </td>
                    </tr>
                </table>

                <!--SUBTOTAL-->
                <table class="table table-borderless mt-4">
                    <tr>
                        <td>Sub Total:</td>
                        <td class="text-right">${{Number(this.$root.$data.details.sub_total).toFixed(2)}}</td>
                    </tr>
                </table>

                <table v-if="this.$root.$data.conditions" class="table table-borderless table-sm">
                    <tr v-for="condition in this.$root.$data.conditions">
                        <td v-if="condition.name == 'IL Sales Tax'" class="pl-3">
                            {{ condition.name }}
                            <div class="text-gray small taxExtemptBtn" data-toggle="modal" data-target="#taxExtempt">
                                <strong>Tax exempt?</strong>
                            </div>   
                        </td>
                        <td v-else class="pl-3">
                            {{ condition.name }}
                            <div class="text-gray small" v-if="condition.attributes.name">Service: {{condition.attributes.name}}</div>
                            <div class="text-gray small" v-if="condition.attributes.taxNote" v-html="condition.attributes.taxNote"></div>
                        </td>
                        <td class="text-right pr-3">${{ Number(condition.attributes.price || 0).toFixed(2) }}</td>
                    </tr>
                </table>

                <!--TOTAL-->
                <table class="table table-borderless mt-4">
                    <tr>
                        <td><h4>Total:</h4></td>
                        <td class="text-right"><h4>${{Number(this.$root.$data.details.total).toFixed(2)}}</h4></td>
                    </tr>
                </table>

                <div v-if="this.showCheckout">
                    <a v-if="this.guest" href="/checkout/login-first" class="btn btn-primary btn-lg btn-block">Checkout</a>
                    <a v-else href="/checkout/address" class="btn btn-primary btn-lg btn-block">Checkout</a>
                </div>

                <a href="/cart" class="btn btn-basic border-none float-right text-upq-orange">EDIT ORDER</a>
            </div>
        </div>
    </div>


</template>

<script>
    export default {

        props: ['showDetails', 'guest', 'showCheckout', 'orderNumber', 'PONumber'],

        methods: {

            pricePerQty(item){
                if(this.balePrice(item) !== false
                    && this.bundlePrice(item) !== false
                    && this.baleQty(item) !== false
                    && this.bundleQty(item) !== false){

                    if(item.quantity >= this.baleQty(item)){
                        return Number(this.balePrice(item));
                    }else{
                        return Number(this.bundlePrice(item));
                    }
                }
                else {
                    var tiers = item.attributes[this.getIndexFor('tiers', item.attributes)].tiers;
                    var tierPrice = 0;
                    tiers.forEach(function (sellTier, index, tierArr) {
                        if (Number(item.quantity) >= Number(sellTier.Quantity)) {
                            tierPrice = sellTier.Price;
                        } else {
                            tierPrice = tiers[0].Price;
                        }
                    });
                    return Number(tierPrice);
                }
            },

            //returns index of attr key or false
            getIndexFor(attrValue, array){
                var indx = -1
                array.forEach(function (value, index) {
                    if(value[attrValue]){
                        indx = index;
                    }
                });
                return indx;
            },

            bundlePrice(item){
                if(this.getIndexFor('bundlePrice',item.attributes) > -1){
                    return  item.attributes[this.getIndexFor('bundlePrice',item.attributes)].bundlePrice;
                }else{
                    return false;
                }
            },

            bundleQty(item){
                if(this.getIndexFor('bundleQty',item.attributes) > -1){
                    return item.attributes[this.getIndexFor('bundleQty',item.attributes)].bundleQty;
                }else{
                    return false;
                }
            },

            balePrice(item){
                if(this.getIndexFor('balePrice',item.attributes) > -1){
                    return  item.attributes[this.getIndexFor('balePrice',item.attributes)].balePrice;
                }else{
                    return false;
                }
            },

            baleQty(item){
                if(this.getIndexFor('baleQty',item.attributes) > -1){
                    return item.attributes[this.getIndexFor('baleQty',item.attributes)].baleQty;
                }else{
                    return false;
                }
            },

            actualItemId(item){
                if(this.getIndexFor('actualItmId',item.attributes) > -1){
                    return item.attributes[this.getIndexFor('actualItmId',item.attributes)].actualItmId;
                }else{
                    return false;
                }
            },
        },

        computed: {
            //value displayed for 'selected quantity'.
            showCheckoutBtn() {

            },
        }
    }
</script>
