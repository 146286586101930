$('#unipaq-stripe-payment-form-card-element').ready(function() {
    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    var style = {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    };

    try {
        // bugsnagClient.notify(new Error('Test Error'));
        // Create a Stripe client.
        var stripe = Stripe(process.env.MIX_STRIPE_KEY);

        // Create an instance of Elements.
        var elements = stripe.elements();

        // Create an instance of the card Element.
        var card = elements.create('card', {style: style});
    }catch (e) {
        bugsnagClient.notify(e);
    }



    // Add an instance of the card Element into the `card-element` <div>.
    if($('#unipaq-stripe-payment-form-card-element').length){
        card.mount('#unipaq-stripe-payment-form-card-element');
        // Handle real-time validation errors from the card Element.
        card.addEventListener('change', function (event) {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });
    }

    // Handle form submission.
    if($('#unipaq-stripe-payment-form-card-element').length){
        try {
            var form = document.getElementById('unipaq-stripe-payment-form');
            if(!form){
                //check that this might be a card update
                form = document.getElementById('upq-stripe-card-update-form');
            }


            form.addEventListener('submit', function (event) {
                event.preventDefault();

                // Gather additional customer data we may have collected in our form.
                var fname = form.querySelector('#firstName');
                var lname = form.querySelector('#lastName');
                var address1 = form.querySelector('#bill_address_1');
                var city = form.querySelector('#bill_city');
                var state = form.querySelector('#bill_state');
                var additionalData = {
                    name: fname ? fname.value + ' ' + lname.value : undefined,
                    address_line1: address1 ? address1.value: undefined,
                    address_city: city ? city.value : undefined,
                    address_state: state ? state.value : undefined
                };

                stripe.createToken(card, additionalData).then(function (result) {

                    if (result.error) {
                        // Inform the user if there was an error.
                        var errorElement = document.getElementById('card-errors');
                        errorElement.textContent = result.error.message;
                    } else {
                        // Send the token to your server.
                        stripeTokenHandler(result.token);
                    }
                });
            });
        }catch (e) {
            bugsnagClient.notify(e);
        }
    }

    // Submit the form with the token ID.
    function stripeTokenHandler(token) {
        try {
            // Insert the token ID into the form so it gets submitted to the server
            var form = document.getElementById('unipaq-stripe-payment-form');
            if(!form){
                //check that this might be a card update
                form = document.getElementById('upq-stripe-card-update-form');
            }
            var hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'stripeToken');
            hiddenInput.setAttribute('value', token.id);
            form.appendChild(hiddenInput);

            // Submit the form
            form.submit();
        }catch (e) {
            bugsnagClient.notify(e);
        }
    }


});
