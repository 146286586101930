<template>
    <div class="upq-cart-badge">
        <i class="fas fa-2x fa-shopping-cart text-black align-middle"></i> 
        <span class="ml-2 text-black">{{this.$root.$data.itemCount}} items</span>
        <!--<flash ref="flashPop" class="alert-flash"></flash>-->
    </div>
</template>

<script>
    import Flash from './Flash.vue';

    export default {

        data() {
            return {
                message: 'Test',
                show: false
            }
        },

        mounted() {
            // console.log('Component mounted.')
            // this.$refs.flashPop.flash('Hello!!');
        }
    }
</script>
