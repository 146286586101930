<template>
    <div class="alert alert-success" role="alert" v-show="show">
        <!--{{ body }}-->
        <div class="lead text-upq-blue font-roboto-slab mb-3">Added to Cart:</div>
        <div v-if="show" class="flex flex-col">
            <div class="px-1 px-md-2 mb-2">
                <img v-lazyload
                     class="img-fluid"
                     :src="itemImage"
                     :data-src="itemImage"
                     data-err="/images/product_placeholder.jpg">
            </div>
            <div class="mb-2">
                {{itemName}} <br>Quantity: {{itemQty}}
            </div>
        </div>
        <!--<span v-html=body></span>-->

        <a v-if="this.guest"href="/checkout/address" class="btn btn-block bg-upq-yellow text-black mt-4">Proceed to Checkout <i class="fas fa-angle-right"></i></a>
        <a v-else href="/checkout/address" class="btn btn-block bg-upq-yellow text-black mt-4">Proceed to Checkout <i class="fas fa-angle-right"></i></a>
        <a href="/cart" class="btn a-basic btn-block">View and Edit Cart</a>
    </div>
</template>

<script>
    export default {

        props: ['message', 'guest', ],

        data() {
            return {
                blurAmt:Number(2),
                item:null,
                body: '',
                show: false
            }
        },

        created() {
            //uncomment this to watch for messages on create,
            // if (this.message) {
            //     this.flash(this.message);
            // }

            window.events.$on('flash', message => {
                this.flash(message, 6000);
            });
        },

        computed: {

            itemImage(){
                let baseUrl = window.location.origin;
                if (this.item == null) return '/images/product_placeholder.jpg';
                if(!this.item.attributes[this.getIndexFor('image',this.item.attributes)] || this.item.attributes[this.getIndexFor('image',this.item.attributes)] == undefined) return '/images/product_placeholder.jpg';
                return baseUrl+'/storage/products/'+
                    this.item.attributes[this.getIndexFor('image',this.item.attributes)].image;
            },
            itemName(){
                if (this.item == null) return;
                return this.item.name;
            },
            itemQty(){
                if (this.item == null) return;
                return this.item.qty;
            }
        },

        methods: {
            flash(message, timeout) {
                // this.body = message;
                this.item = message;
                this.itemImage;

                this.show = true;
                if(timeout != null){
                    this.hide(timeout);
                }else{
                    this.hide(4800);
                }
            },

            hide(tieout) {
                setTimeout(() => {
                    this.show = false;
                }, tieout);
            },

            //returns index of attr key or false
            getIndexFor(attrValue, array){
                var indx = -1
                array.forEach(function (value, index) {
                    if(value[attrValue]){
                        indx = index;
                    }
                });
                return indx;
            }
        },

        mounted() {}
    }
</script>

<style>
    .alert-flash {
        position: fixed;
        right: 25px;
        top: 25px;
        width: 350px;
        z-index: 9999;
    }
    .alert-flash:hover {
        display: block !important;
    }

    @media (max-width: 767.98px) {
        .alert-flash {
            width: 300px;
            right: 10px;
        }
    }
</style>
