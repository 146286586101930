<template>
    <div class="col-12 col-md-4 px-0">
        <form class="form-inline" ><!--v-on:submit="submitSearch"-->
            <label class="sr-only">Search dimensions (L x W x D)</label>
            <vue-bootstrap-typeahead
                :data="dimensions"
                name="selectedDimension"
                v-model="dimensionSearch"
                placeholder="Search dimensions (L x W x D)"
                :minMatchingChars="minChars"
                @hit="selectedDimension = $event"
                class="mb-2 w-75"
                inputClass="form-control"
                ref="dimTypeahead"
            />
            <div class="form-group w-25 mb-0">
                <label class="sr-only" for="submit">Search</label>
                <a :href="requestUrl" type="submit" ref="dimSubmit" class="btn btn-primary mb-2 w-100"><span class="glyphicon glyphicon-search"></span> Search</a>
            </div>

        </form>
    </div>
</template>

<script>
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

    // const API_URL = 'https://api-url-here.com?query=:query'

    export default {
        name: 'DimesnionSearch',

        props:['apiUrl', 'searchString', 'departmentId'],

        components: {
            VueBootstrapTypeahead
        },

        computed: {
            // a computed getter
            requestUrl: function () {
                var url = this.apiUrl + '/products';
                if(this.selectedDimension){
                    var cleanDimension = this.selectedDimension.replace(/\//g,'-!-');
                    url+= '/' + cleanDimension;
                    if(this.departmentId !== null){
                        url+= ('&depId=' + this.departmentId);
                    }
                }else{
                    if(this.departmentId !== null){
                        url = this.apiUrl + '/department/' + this.departmentId;
                        // url+= ('/&depId=' + this.departmentId);
                    }
                }
                return url;
            }
        },

        mounted: function () {
            this.$refs.dimTypeahead.inputValue = this.searchString;
            // var input = $('.input-field-display');
            // if(input){
            //     input.value = this.searchString;
            // }
        },

        data() {
            return {
                dimensions: [],
                dimensionSearch: '',
                selectedDimension: null,
                minChars:1
            }
        },

        methods: {
            async getDimensions(query) {
                // const res = await fetch(this.apiUrl.replace(':query', query))
                var queryUrl = this.apiUrl + '/autocomplete/' + query
                //narrow down by department if provided.
                if(this.departmentId !== null){
                    queryUrl += '&departmentId=' + this.departmentId;
                }
                const res = await fetch(queryUrl);
                const suggestions = await res.json();
                this.dimensions = suggestions;
            }
        },

        watch: {
            dimensionSearch: _.debounce(function(dim) { this.getDimensions(dim) }, 500),
            selectedDimension: function(hit){
                var url = this.apiUrl + '/products';
                // console.info('App currentRoute:', location.pathname);
                var cleanDimension = hit.replace(/\//g,'-!-');
                url+= '/' + cleanDimension;
                if(this.departmentId !== null){
                    url+= ('&depId=' + this.departmentId);
                }
                this.$refs.dimSubmit.href = url;
                this.$refs.dimSubmit.click();
                // axios.get('/products',{
                //     dimensions:hit,
                // }).then(function(success) {
                //
                // }, function(error) {
                //     console.log(error);
                // });
            }
        }
    }
</script>

<style>
    .input-group-w-100 .input-group {
        width: 100%;
    }

    .min-w-250{
        min-width: 250px;
    }
</style>
