<template>
        <button v-on:click="removeProduct" class="btn btn-light" alt="Delete from cart"><i class="fas fa-times"></i></button>
</template>

<script>
    export default {

        props: ['productId'],

        methods: {
            removeProduct(){
                this.$root.removeItem(this.productId);
            }
        }
    }
</script>
