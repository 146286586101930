
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./stripe-elements-checkout');

window.Vue = require('vue');

import VTooltip from "v-tooltip";
import VueTinyLazyloadImg from 'vue-tiny-lazyload-img';

Vue.use(VTooltip);
Vue.use(VueTinyLazyloadImg);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
Vue.component('flash', require('./components/Flash.vue').default);
Vue.component('shoppingCart', require('./components/ShoppingCart.vue').default);
Vue.component('qtySelector', require('./components/QtySelector.vue').default);
Vue.component('deleteBtn', require('./components/DeleteBtn.vue').default);
Vue.component('cartOverview', require('./components/CartOverview.vue').default);
Vue.component('cartDetailTable', require('./components/CartDetailTable.vue').default);
Vue.component('dimension-search-typeahead', require('./components/DimensionSearchTypeahead.vue').default);
// Vue.component('carousel', require())
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

var _token = $('meta[name="csrf-token"]').attr('content');

const app = new Vue({
    el: '#app',
    data: {
        details: {
            sub_total: 0,
            total: 0,
            total_quantity: 0
        },
        favoriteProducts:[],
        itemCount: 0,
        items: [],
        conditions:[],
        cartCondition: {
            name: 'placeholder',
            type: 'placeholder',
            target: 'placeholder',
            value: '0',
            attributes: {
                description: 'Value Added Tax'
            }
        },

        options: {
            target: [
                {label: 'Apply to SubTotal', key: 'subtotal'},
                {label: 'Apply to Total', key: 'total'}
            ]
        }
    },
    mounted:function(){
        this.loadItems();
        this.loadFavorites();
    },
    methods: {
        updateQty: function(item){
            var _this = this;

            return axios.post('/cart/update-qty',{
                _token:_token,
                id:item.id,
                quantity:Number(item.qty),
                price:item.price,
                attributes:item.attributes
            }).then(function(success){
                _this.clearCartCondition();
                return _this.loadItems()
                    .then(response=>{
                        if(response !== true){
                            return response;
                        }else{
                            return true;
                        }
                    })
                    .catch(error => {return error;});
                // _this.$refs.flashPop.flash('Cart Updated!');
            })
            .catch(error => {return error;});
        },

        addItem: function(item) {
            //Price is determined based on product qty. If this product supports bale prices then
            // check if the qty ordered is equal or divisible by the bale qty and pass bale price if true.
            let price = item.price,
            leftOver = 0,
            balesQty = 0;
            if(item.attributes[0].baleQty != null && item.attributes[0].baleQty != undefined && item.attributes[0].balePrice != null && item.attributes[0].balePrice != undefined && item.qty >= item.attributes[0].baleQty){
                if(Number.isInteger(item.qty / item.attributes[0].baleQty)){
                    price = item.attributes[0].balePrice;
                }
                //if the qty does not divide to bale qty in a whole number then subtract the qty which is left after full possible bale price qty.
                else{
                    balesQty = parseInt(item.qty / item.attributes[0].baleQty);
                    leftOver = item.qty - item.attributes[0].baleQty * parseInt(item.qty / item.attributes[0].baleQty);
                }
            }

            let result;
            if(balesQty != 0 || price != item.price){
                let inBaleItemQty = parseInt(item.qty / item.attributes[0].baleQty) * item.attributes[0].baleQty;
                result = this.addSelectedItemToCart(item, item.attributes[0].balePrice, inBaleItemQty, true);
            }
            if(leftOver != 0){
                result = this.addSelectedItemToCart(item, item.price, leftOver, false);
            }
            //regular item order no bale, no leftover from the bale calculations.
            if(leftOver === 0 && balesQty === 0 && price === item.price){
                result = this.addSelectedItemToCart(item, item.price, item.qty, false)
            }

            return result;
        },

        addSelectedItemToCart: function(item, price, qty, isBale){
            var _this = this;
            return axios.post('/cart',{
                _token:_token,
                id: (isBale)? item.id + '-B' : item.id,
                name:item.name,
                price: price,
                quantity:Number(qty),
                attributes:item.attributes
            })
            .then(function(success) {
                _this.clearCartCondition();
                _this.$refs.flashPop.flash( item );
                return _this.loadItems()
                    .then((result)=>{
                        if(result !== true){
                            return result;
                        }else{
                            return true;
                        }
                    })
                    .catch(error => {return error;});
            })
            .catch(error => {return error;});
        },

        addCartCondition: function() {
            var _this = this;
            axios.post('/cart/conditions',{
                _token:_token,
                name:_this.cartCondition.name,
                type:_this.cartCondition.type,
                target:_this.cartCondition.target,
                value:_this.cartCondition.value,
            }).then(function(success) {
                _this.loadItems()
                    .then((result)=>{
                            if(result !== true){
                                return result;
                            }else{
                                return true;
                            }
                        })
                    .catch(error => {return error;});
                })
                .catch(error => {return error;});
        },

        clearCartCondition: function() {
            var _this = this;

            axios.delete('/cart/conditions?_token=' + _token)
            .then(function(success) {
                _this.loadItems()
                .then((result)=>{
                    if(result !== true){
                        return result;
                    }else{
                        return true;
                    }
                })
                .catch(error => {return error;});
            }).catch(error => {return error;});
        },

        clearCart: function(){
            var _this = this;

            axios.get('/cart/clear').then(function(success) {
                _this.clearCartCondition();
                _this.loadItems()
                .then((result)=>{
                    if(result !== true){
                        return result;
                    }else{
                        return true;
                    }
                })
                .catch(error => {return error;});
            }).catch(error => {return error;});
        },

        removeItem: function(id) {

            var _this = this;

            return axios.delete('/cart/'+id,{
                params: {
                    _token:_token
                }
            }).then(function(success) {
                _this.clearCartCondition();
                return _this.loadItems()
                .then((result)=>{
                    if(result !== true){
                        return result;
                    }else{
                        return true;
                    }
                })
                .catch(error => {return error;});
                // _this.$refs.flashPop.flash('Item removed from cart.');
            }).catch(error => {return error;});
        },

        loadFavorites: function(){
            var _this = this;
            return axios.get('/favorites').then(success=>{
                _this.favoriteProducts = success.data.data;
            }).catch(error => {
                console.log(error);
                return error;
            });
        },

        loadItems: function() {

            var _this = this;

            return axios.get('/cart-items',{
                // params: {
                //     limit:10
                // }
            }).then( success=>{
                success.data.data.sort(function(a, b) {
                    var textA = a.name.toUpperCase();
                    var textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                _this.items = success.data.data;
                _this.itemCount = success.data.data.length;
                _this.loadCartDetails();
                return true;
            })
            .catch(error => {
                console.log(error);
                return error;
            });
        },

        loadCartDetails: function() {
            var _this = this;

            axios.get('/cart/details').then(function(success) {
                _this.details = success.data.data;
                _this.loadCartConditions();
            }, function(error) {
                console.log(error);
            });
        },

        loadCartConditions: function(){
            var _this = this;

            axios.get('/cart/get-conditions').then(function(success) {
                _this.conditions = success.data.data;
            }, function(error) {
                console.log(error);
            });
        },
    }
});
